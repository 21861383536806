
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ListPaymentData from "@/data/payment";
import LoanData from "@/data/loan";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { saveFile } from "@/core/services/FileSaverService";
import store from "@/store";

export default defineComponent({
  name: "listPayments",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Төлөлтийн мэдээлэл");
    });
    const isu = store.getters.getMbUser as boolean;

    return {
      isu
    };
  },
  data() {
    return {
      loadingTable: true,
      downloadInvoice: false,
      paymentInfo: {
        page: 1,
        pageSize: 10,
        invoiceSearch: "",
        yearValue: new Date().getFullYear().toString(),
        year: ListPaymentData[0]["year"],
        monthValue:
          (new Date().getMonth() + 1).toString().length === 2
            ? (new Date().getMonth() + 1).toString()
            : "0" + (new Date().getMonth() + 1).toString(),
        months: ListPaymentData[0]["months"],
        statusType: LoanData[0]["statusType"],
        selectVal: "07",
        orgList: [],
        selectOrgVal: "*",
        tableData: []
      },
      voucherList: {
        page: 1,
        pageSize: 5,
        sttSearch: "",
        yearValue: "",
        year: ListPaymentData[0]["year"],
        monthValue: "",
        months: ListPaymentData[0]["months"],
        tableData: [],
        payableVoucherList: [],
        selectedPayableInvoice: "",
        selectedInvoice: {
          invoiceNumber: "",
          invoiceDate: "",
          invoiceAmount: "",
          status: "",
          paidDate: "",
          payableAmount: "",
          orgcode: "",
          diffStatus: "",
          amntDiff: "",
          orgname: "",
          orgtype: ""
        },
        savePaymentData: {
          voucherid: "",
          invoiceNumber: ""
        },
        selectedVoucher: {
          amount: "",
          accountName: "",
          accountNumber: "",
          narration: "",
          bankcode: "",
          voucherid: "",
          voucherdate: "",
          isConfirmed: ""
        }
      },
      inqClosedOrg: {
        page: 1,
        pageSize: 5,
        inqClsSearch: "",
        tableData: []
      }
    };
  },
  mounted() {
    this.getOrg();
    this.getPaymentList();
    this.getVoucherList();
  },
  computed: {
    filteredOrgList(): any {
      return this.paymentInfo.orgList.filter((data: any) => {
        return (
          !this.paymentInfo.selectVal ||
          data.selectOrgType.includes(this.paymentInfo.selectVal.toLowerCase())
        );
      });
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.paymentInfo.page = val;
    },
    handleChange(value) {
      this.paymentInfo.pageSize = value;
    },
    handleCurrentChangeVoucherList(val) {
      this.voucherList.page = val;
    },
    handleChangeVoucherList(value) {
      this.voucherList.pageSize = value;
    },
    handleCurrentChangeInqClosed(val) {
      this.inqClosedOrg.page = val;
    },
    handleChangeInqClosed(value) {
      this.inqClosedOrg.pageSize = value;
    },
    fillVoucherList(row) {
      this.voucherList.selectedVoucher.amount = row.amount;
      this.voucherList.selectedVoucher.accountName = row.accountName;
      this.voucherList.selectedVoucher.accountNumber = row.accountNumber;
      this.voucherList.selectedVoucher.narration = row.narration;
      this.voucherList.selectedVoucher.bankcode = row.bankcode;
      this.voucherList.selectedVoucher.voucherid = row.voucherid;
      this.voucherList.selectedVoucher.voucherdate = row.voucherdate;
      this.voucherList.selectedVoucher.isConfirmed = row.isConfirmed;
      this.voucherList.savePaymentData.voucherid = row.voucherid;
    },
    async getInvoice(invoicenumber) {
      this.downloadInvoice = true;
      const params = {
        invoicenumber: invoicenumber
      };
      await ApiService.post(`banksinvoice`, {
        params
      })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            saveFile(
              results.response,
              invoicenumber,
              "application/pdf",
              ".pdf"
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Нэхэмжлэл татахад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.downloadInvoice = false;
        });
    },
    async savePayment() {
      if (
        this.voucherList.savePaymentData.voucherid === "" ||
        this.voucherList.savePaymentData.voucherid === null ||
        this.voucherList.savePaymentData.invoiceNumber === "" ||
        this.voucherList.savePaymentData.invoiceNumber === null
      ) {
        ElNotification({
          title: "Анхааруулга",
          message: "Аль нэг талбар сонгоогүй байна.",
          type: "warning"
        });
      } else {
        Swal.fire({
          text: "Төлбөр төлөх үү?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Төлөх!",
          cancelButtonText: "Цуцлах"
        }).then(async result => {
          if (result.isConfirmed) {
            await ApiService.post(`inquiryvoucherlist`, {
              params: {
                voucherid: this.voucherList.savePaymentData.voucherid,
                invoiceNumber: this.voucherList.savePaymentData.invoiceNumber
              }
            })
              .then(resp => {
                const results = resp.data;
                if (results.code === 0) {
                  ElNotification({
                    title: "Алдаа",
                    message: results.error,
                    type: "error"
                  });
                } else if (results.code === 401) {
                  ElNotification({
                    title: "Анхааруулга",
                    message: results.warning,
                    type: "warning"
                  });
                } else {
                  ElNotification({
                    title: "Амжилттай",
                    message: results.response,
                    type: "success"
                  });
                  window.location.reload();
                }
              })
              .catch(() => {
                ElNotification({
                  title: "Алдаа",
                  message: "Өгөгдөл уншихад алдаа гарлаа.",
                  type: "error"
                });
              });
          }
        });
      }
    },
    async getPaymentList() {
      this.loadingTable = true;
      await ApiService.get(`inquiryinvoice`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.paymentInfo.tableData = results.response;
            this.voucherList.payableVoucherList = this.paymentInfo.tableData.filter(
              (data: any) => {
                return (
                  (data.status === "A" && data.diffStatus !== null) ||
                  data.status === "C"
                );
              }
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    async getVoucherList() {
      this.loadingTable = true;
      await ApiService.get(`inquiryvoucherlist`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.voucherList.tableData = results.response;
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    async getOrg() {
      this.loadingTable = true;
      await ApiService.get(`org`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning"
            });
          } else {
            this.paymentInfo.orgList = results.response.map(org => ({
              selectOrgVal: org.orgcode,
              selectOrgType: org.orgtypecode,
              selectOrgLabel: org.orgname
            }));
            this.inqClosedOrg.tableData = results.response.filter(
              (data: any) => {
                return data.isInquiryOpen === 1;
              }
            );
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    }
  }
});
